<template>
  <div class="wrapper">
    <base-button
      icon
      type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon
      type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{ tagBtn: isActive }" />
    <div class="main-content" :class="{ DashboardBody: isActive }">
      <SampleNavbar />
      <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
        <b-breadcrumb>
          <b-breadcrumb-item href="#">Service</b-breadcrumb-item>
          <b-breadcrumb-item href="#" active>Add Service</b-breadcrumb-item>
        </b-breadcrumb>
      </base-header>
      <b-container fluid class="mt--7">
        <b-card type="default" header-classes="bg-transparent">
          <b-form style="text-align: left" ref="form">
            <b-row>
              <b-col md="3" class="mt-4">
                <label>Page Name *</label>
                <b-input
                  @input="urlAdd(serviceData.pageName)"
                  type="text"
                  placeholder="Add Page Name"
                  v-model="serviceData.pageName"
                ></b-input>
              </b-col>
              <b-col md="3" class="mt-4">
                <label>Page Url *</label>
                <b-input
                  type="text"
                  placeholder="Add Page Url"
                  v-model="serviceData.pageUrl"
                ></b-input>
              </b-col>
              <b-col md="3" class="mt-4">
                <label>Image Url *</label>
                <b-input
                  type="text"
                  placeholder="Add Image Url"
                  v-model="serviceData.imageUrl"
                ></b-input>
              </b-col>
              <b-col md="3" class="mt-4">
                <label>Page Small Details *</label>
                <b-input
                  type="text"
                  placeholder="Add Page Small Details"
                  v-model="serviceData.pageDes"
                ></b-input>
              </b-col>
              <b-col md="12" class="mt-4">
                <label>Page Details *</label>
                <!-- <JsonEditor
                    :options="{
                      confirmText: 'confirm',
                      cancelText: 'cancel',
                    }"
                    :objData="serviceData.editorData" 
                    v-model="serviceData.editorData" >
                  </JsonEditor> -->
                <!-- <div id="editorBoot"></div> -->
                <v-md-editor
                  v-model="serviceData.editorData"
                  height="400px"
                ></v-md-editor>
              </b-col>
              <!-- <b-col md="6" class="mt-4">
                  <label>Page Details *</label>
                  <div>{{serviceData.editorData}}</div>
                  <div id="editorBoot"></div>
                </b-col> -->
            </b-row>
            <b-row class="mt-4">
              <b-col md="9"></b-col>
              <b-col md="3" class="mt-4"
                ><base-button block type="primary" @click="addService"
                  >Add Service</base-button
                ></b-col
              >
            </b-row>
            <b-row>
              <b-col md="9"></b-col>
              <b-col md="3" class="mt-4">
                <base-button
                  block
                  type="primary"
                  @click="$router.push({ name: 'service-list' })"
                  >View Service</base-button
                >
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-container>
      <Footer />
    </div>
  </div>
</template>
<script>
import Sidebar from "./../../Starter/Sidebar";
import SampleNavbar from "./../../Starter/SampleNavbar";
import Footer from "./../../Starter/SampleFooter";
import { API_URL } from "../../../router/api";
import { VueEditor } from "vue2-editor";
// import { ImageDrop } from "quill-image-drop-module";
// import ImageResize from "quill-image-resize-module";
export default {
  name: "add-service",
  components: {
    Sidebar,
    SampleNavbar,
    Footer,
    VueEditor
  },
  data() {
    return {
      isActive: false,
      serviceData: {
        pageId: null,
        pageName: null,
        pageUrl: null,
        pageDes: null,
        imageUrl: null,
        editorData: ""
      }
    };
  },
  computed: {
    ser() {
      return this.$store.state.servicelist == null
        ? {}
        : this.$store.state.servicelist.find(data => {
            return data.service_id === this.$route.params.serId;
          });
    }
  },
  methods: {
    toggleClass: function(event) {
      this.isActive = !this.isActive;
    },
    urlAdd(data) {
      let str = data;
      this.serviceData.pageUrl = str
        .toLowerCase()
        .trim()
        .split(/\s+/)
        .join("-");
    },
    addService() {
      const self = this;
      console.log(self.serviceData);
      let api = {
        callBack() {
          self.axios
            .post(API_URL + "admin_add_service", self.serviceData)
            .then(response => {
              let obj = response.data;
              console.log(obj);
              obj.status == true
                ? self.$store.dispatch("alertSuccess", obj.message)
                : self.$store.dispatch("alertError", obj.message);
              self.$store.dispatch("getServiceList");
            });
          self.serviceData = {};
        }
      };
      this.serviceData.pageName == null || this.serviceData.pageName == ""
        ? self.$store.dispatch("alertError", "Page Name Empty")
        : this.serviceData.pageUrl == null || this.serviceData.pageUrl == ""
        ? this.$store.dispatch("alertError", "Page Url Empty")
        : this.serviceData.pageDes == null || this.serviceData.pageDes == ""
        ? this.$store.dispatch("alertError", "Page Small Details Empty")
        : this.serviceData.imageUrl == null || this.serviceData.imageUrl == ""
        ? this.$store.dispatch("alertError", "Image Url Empty")
        : this.serviceData.editorData == ""
        ? this.$store.dispatch("alertError", "Long Details Empty")
        : api.callBack();
    }
  },
  mounted() {
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://cloud.n1ed.com/cdn/3R4CDFLT/n1tinymce.js')
    // document.head.appendChild(recaptchaScript)
    // window.onEditorLoaded = function() {
    //     tinymce.init({selector: "#editorBoot"});
    // }
    this.serviceData = {
      pageId: this.ser.service_id,
      pageName: this.ser.service_title,
      pageUrl: this.ser.service_url,
      pageDes: this.ser.service_smalldetails,
      imageUrl: this.ser.service_img_link,
      editorData: this.ser.service_pagedetails
    };
  }
};
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
.ve-fullscreen {
  z-index: 150001 !important;
}
</style>
